import { MenuIcon } from "@heroicons/react/solid";
import React, { FC, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import SubscriptionModal from "../../containers/letsPlay/SubscriptionModal";
import AuthContext from "../context/authContext/AuthContext";
import { ACTIONS } from "../context/authContext/Constants";
import { SubscriptionTierEnum } from "../enums/SubscriptionTier";
import { AUTH_ROUTES } from "../routes/Constants";
import logoImage from "./../../../assets/images/logo.png";
import { AppRoles, SubRoles } from "../enums/role.enum";
import {
    loggedUserWithStudent,
    setParentProfile,
} from "../services/auth/auth.service";
import { IUser } from "../interfaces/user.interface";
import { useIntercom } from "react-use-intercom";
import useLogoutAllTabs from "../hooks/logoutAllTabs";

const REACT_APP_PORTAL_URL = process.env[`REACT_APP_PORTAL_URL`];
const REACT_APP_URL = process.env[`REACT_APP_URL`];

interface IProps {
    openSideBar: () => void;
}

const Header: FC<IProps> = (props) => {
    const { openSideBar } = props;
    const { state: authState, dispatch } = useContext(AuthContext);
    const { postMessage } = useLogoutAllTabs();
    
    const { shutdown: shutdownIntercom } = useIntercom();

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

    const history = useHistory();

    const [selectedProfile, setSelectedProfile] =
        useState<Partial<IUser | null>>(null);

    const getUserFromApi = async () => {
        const { response, errors } = await loggedUserWithStudent();

        if (!errors) {
            const students = response?.parentInfo?.students?.length
                ? response.parentInfo.students.map(
                      (student: Partial<IUser>) => {
                          return {
                              id: student.id,
                              firstname: student.firstname,
                              lastname: student.lastname,
                              fullname: `${student.firstname} ${student.lastname}`,
                              role: AppRoles.STUDENT,
                          };
                      }
                  )
                : [];

            dispatch({
                type: ACTIONS.SET_STUDENTS_UNDER_USER,
                students,
            });

            const parent = {
                id: response.id,
                firstname: response.firstname,
                lastname: response.lastname,
                fullname: `${response.firstname} ${response.lastname}`,
                role: AppRoles.PARENT,
            };

            if (!response.profileStudentUsed) {
                if(students?.length) {
                    setSelectedProfile(students[0]);
                } else {
                    setSelectedProfile(parent);
                }
            }
            
            if(response?.id !== selectedProfile?.id) {
                if(selectedProfile) {
                    updateProfile(selectedProfile)
                }
            }
        }
    };

    const updateProfile = async (value: Partial<IUser>) => {
        const currentProfile = selectedProfile;
        try {
            setSelectedProfile(value);
            await setParentProfile(
                value.role === AppRoles.PARENT ? null : value.id!
            );
        } catch (error) {
            setSelectedProfile(currentProfile);
        }
    };

    const ProfileHeaderSelector = () => {
        return (
            <div className="flex-shrink-0">
                {authState.user?.firstname}{" "}
                {authState.user?.lastname?.charAt(0)}.
            </div>
        );
    };

    const handleClickPortal = (type: "web" | "mobile") => {
        const user = authState.user;

        if (user?.subscriptionTier === SubscriptionTierEnum.FREE) {
            setShowSubscriptionModal(true);
        } else {
            if (type === "mobile") {
                window.open(`${REACT_APP_PORTAL_URL}/auth/login`, "_blank");
            } else {
                window.open(
                    `${REACT_APP_URL}/app/admin-portal`,
                    "_blank"
                );
            }
        }
    };

    const handleClickSubscribeNow = () => {
        setShowSubscriptionModal(true);
    };

    useEffect(() => {
        const historyListener = history.listen(() => {
            setShowMobileMenu(false);
        });

        return () => {
            historyListener();
        };
    }, []);

    useEffect(() => {
        getUserFromApi();
    }, []);

    const renderMobileMenu = () => (
        <div className="absolute left-2 right-2 p-2 bg-white shadow-2xl border border-aha-light-blue z-10">
            <div className="space-y-3 px-2 capitalize">
                {authState.user &&
                (authState.user.subscriptionTier ===
                    SubscriptionTierEnum.FREE ||
                    authState.user.subscriptionTier ===
                        SubscriptionTierEnum.FREE_TRIAL) ? (
                    <div
                        className="cursor-pointer"
                        onClick={handleClickSubscribeNow}
                    >
                        subscribe now
                    </div>
                ) : null}

                <div>
                    {authState?.user?.role === AppRoles.PARENT ? (
                        <a
                            href={`${REACT_APP_PORTAL_URL}/auth/login`}
                            target="_blank"
                        >
                            parent portal
                        </a>
                    ) : null}
                </div>

                <div>
                    {authState?.user?.role === AppRoles.CLINICIAN ? (
                        <span onClick={() => handleClickPortal("mobile")}>
                            clinician portal
                        </span>
                    ) : null}
                </div>

                {authState.user ? (
                    <div>
                        <button
                            onClick={() => {
                                shutdownIntercom();
                                postMessage('logout');
                                dispatch({
                                    type: ACTIONS.LOGOUT,
                                });
                            }}
                        >
                            Log Out
                        </button>
                    </div>
                ) : (
                    <>
                        <div>
                            <Link to={`${AUTH_ROUTES.REGISTER}`}>Sign up</Link>
                        </div>
                        <div>
                            <Link to={`${AUTH_ROUTES.LOGIN}`}>Login</Link>
                        </div>
                    </>
                )}
            </div>
        </div>
    );

    const renderWebPortalButton = () => {
        if (authState) {
            const isParent = authState.user?.role === AppRoles.PARENT;
            const isClinician = authState.user?.role === AppRoles.CLINICIAN;
            const isTeacher = authState.user?.parentInfo?.subRole === SubRoles.TEACHER;

            if (isParent) {
                return (
                    <a
                        href={`${REACT_APP_URL}/app/admin-portal`}
                        target="_blank"
                        className={
                            "text-aha-black py-1 px-2 border-r-2 border-aha-green"
                        }
                    >
                        {isTeacher ? 'teacher portal' : 'parent portal'}
                    </a>
                );
            } else if (isClinician) {
                return (
                    <span
                        onClick={() => handleClickPortal("web")}
                        className={
                            "text-aha-black py-1 px-2 border-r-2 border-aha-green cursor-pointer"
                        }
                    >
                        clinician portal
                    </span>
                );
            }
        }
    };

    const renderWebSubscribeButton = () => {
        if (authState) {
            const subTier = authState.user?.subscriptionTier;
            const show =
                subTier === SubscriptionTierEnum.FREE ||
                subTier === SubscriptionTierEnum.FREE_TRIAL;

            if (show) {
                return (
                    <div
                        onClick={handleClickSubscribeNow}
                        className={
                            "text-aha-green py-1 px-2 border-r-2 border-aha-green cursor-pointer"
                        }
                    >
                        subscribe now
                    </div>
                );
            }
        }
    };

    const renderWebLoginOutButtons = () => {
        if (authState.user) {
            return (
                <>
                    {ProfileHeaderSelector()}

                    <div>
                        <button
                            className={
                                "border border-aha-orange bg-aha-orange text-white py-1 px-6 hover:text-aha-orange hover:bg-white transition duration-200 ease-in-out"
                            }
                            onClick={() => {
                                shutdownIntercom();
                                postMessage('logout');
                                dispatch({ type: ACTIONS.LOGOUT });
                            }}
                        >
                            Log Out
                        </button>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <Link
                        to={`${AUTH_ROUTES.REGISTER}`}
                        className={"text-aha-green py-1 "}
                    >
                        SIGN UP
                    </Link>
                    <div className={"text-gray-500"}>OR</div>
                    <Link
                        to={`${AUTH_ROUTES.LOGIN}`}
                        className={
                            "border border-aha-orange bg-aha-orange text-white py-1 px-4 hover:text-aha-orange hover:bg-white transition duration-200 ease-in-out"
                        }
                    >
                        LOG IN
                    </Link>
                </>
            );
        }
    };

    return (
        <>
            <div className={"border-b-12 py-4 border-aha-green"}>
                <div
                    className={
                        "flex container gap-10  justify-between items-end relative"
                    }
                >
                    <div className={"flex"}>
                        <Link to={authState.token ? "/app/lets-play" : "/"}>
                            <img
                                src={logoImage}
                                alt="At Home Articulation Logo"
                                className={"w-32"}
                            />
                        </Link>
                        <div
                            className="cursor-pointer space-y-2 ml-10 mt-10"
                            onClick={openSideBar}
                        >
                            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
                        </div>
                    </div>

                    {/* MOBILE HEADER */}
                    <div className="md:hidden pr-4">
                        <div className="flex items-center gap-2">
                            {ProfileHeaderSelector()}
                            <div>
                                <MenuIcon
                                    className="w-8"
                                    onClick={() =>
                                        setShowMobileMenu(!showMobileMenu)
                                    }
                                />
                            </div>
                        </div>
                        {showMobileMenu && renderMobileMenu()}
                    </div>

                    {/* WEB HEADER */}
                    <div
                        className={
                            "flex-1 items-center justify-end gap-2 tracking-widest hidden md:flex"
                        }
                    >
                        {renderWebSubscribeButton()}

                        {renderWebPortalButton()}

                        {renderWebLoginOutButtons()}
                    </div>
                </div>
            </div>

            {showSubscriptionModal && (
                <SubscriptionModal
                    skipRoleDescription={true}
                    showModal={showSubscriptionModal}
                    onClose={() => {
                        setShowSubscriptionModal(false);
                    }}
                />
            )}
        </>
    );
};

export default Header;
